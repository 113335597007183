import React, { useState } from 'react';
import { PDPPageContainer } from 'components/Pdp';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
  BundleItem,
  BundleItemDescription,
  BundleItemImageContainer,
  BundleItemListContainer,
  IngredientsButton,
  IngredientsButtonWrapper,
  NsfCertOverlay,
  ValueOverlay,
  ValueOverlayContainer,
  NsfCerfButton,
  Spacer,
} from './BundleItemList.styles';
import { Modal, RedesignModalView } from 'components/Modal';
import { IngredientsModalContent } from '../AddToCartBlock/AddToCartBlock.styles';
import { H2 } from 'components/Common/Text';
import nsfCertSvg from 'svg/nsf-cert.svg';
import { getPriceFormatter } from '@bbnb/openfit-frontend-shared';
import { ProductImage } from 'components/Product/ProductImage';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { SanityImage } from 'graphql-types';
import { useBundleProductContext } from 'Context/BundleProduct/BundleProduct.context';

const NutritionModal: React.FC<{ image: IGatsbyImageData; link: string }> = ({
  image,
  link,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <>
      <IngredientsButtonWrapper>
        <IngredientsButton
          onClick={(e) => {
            e.preventDefault();
            setModalOpen(true);
          }}
        >
          View Nutrition Label
        </IngredientsButton>
      </IngredientsButtonWrapper>
      <Spacer />
      <IngredientsButtonWrapper>
        <NsfCerfButton href={link} target="_blank">
          View our NSF for Sport Certification
        </NsfCerfButton>
      </IngredientsButtonWrapper>

      {isModalOpen && (
        <RedesignModalView>
          <Modal
            showBtnClose
            onOverlayClick={() => setModalOpen(false)}
            onBtnCloseClick={() => setModalOpen(false)}
          >
            <IngredientsModalContent>
              <GatsbyImage image={image} alt="Ingredients" />
            </IngredientsModalContent>
          </Modal>
        </RedesignModalView>
      )}
    </>
  );
};

export const BundleItemList: React.FC = () => {
  const { bundleItems } = useBundleProductContext();
  const format = getPriceFormatter('USD', 'en-US', 'simple');

  return (
    <PDPPageContainer>
      <BundleItemListContainer>
        {bundleItems.map((item, index) => {
          const ingredientsImage = getSimplifiedImageData(
            item.ingredientsImage
          );
          const selectedProductImage = getSimplifiedImageData(
            item.image as SanityImage
          );

          const productImage = selectedProductImage && (
            <ProductImage
              image={{
                alt: 'Tile Image',
                image: selectedProductImage,
                objectFit: 'contain',
                objectPosition: '50% 50%',
              }}
            />
          );

          return (
            <BundleItem key={`bundleItem-${index}`}>
              {item.image && (
                <BundleItemImageContainer>
                  {productImage}
                  {item.isFreeWithOrigPrice && (
                    <ValueOverlayContainer>
                      <ValueOverlay>{`${format(
                        item?.origPrice || 0
                      )} value`}</ValueOverlay>
                    </ValueOverlayContainer>
                  )}
                  {item.nsfCertification && (
                    <NsfCertOverlay icon={nsfCertSvg} width="63" height="26" />
                  )}
                </BundleItemImageContainer>
              )}
              <BundleItemDescription>
                <H2>{item.name}</H2>
                <p>{item.description}</p>
              </BundleItemDescription>
              {ingredientsImage && (
                <NutritionModal
                  image={ingredientsImage}
                  link={item.nsfCertificationLink}
                />
              )}
            </BundleItem>
          );
        })}
      </BundleItemListContainer>
    </PDPPageContainer>
  );
};
