import React from 'react';
import { renderBlockWithId } from 'helpers/block-with-id';
import { BlockType } from 'types';

import {
  BundleAddToCartBlockFragment,
  LadderBundleProductPageBySlugQuery,
  ProductReviewsBlockFragment,
  SanityEmailSignupBlockFragment,
  SanityProductAboutFragment,
  SanityProductFaqFragment,
  SanityLadderProductSliderFragment,
  SanitySellPageSpacer,
  SanitySimpleMediaWithTextBlockFragment,
  SanitySquareImageSliderFragment,
} from 'graphql-types';

import { ProductFAQSanity } from 'components/Product/ProductFAQ/ProductFAQSanity';
import { SanityEmailSignUp } from 'components/EmailSignUp/SanityEmailSignUp';
import { SimpleMediaWithText } from 'components/SimpleMediaWithText';
import { ProductAboutSanity } from 'components/Product/ProductAbout/ProductAbout.sanity';
import { SanityReviewSection } from 'components/Product/ReviewSection';
import { SanitySquareImageSlider } from 'components/SquareImageSlider/SanitySquareImageSlider';
import { Spacer } from 'components/Spacer/Spacer';
import { PDPPageContainer } from 'components/Pdp';
import { BundleItemList } from 'components/Product/BundleItemList';
import { BundleAddToCartBlock } from 'components/Product/BundleAddToCartBlock/BundleAddToCartBlock';
import { ProductSliderSanity } from 'components/Product/Slider/ProductSlider.sanity';
import { DEFAULT_BUY_BUTTON_ACTION } from 'Constants';

type BlocksArrayType = NonNullable<
  LadderBundleProductPageBySlugQuery['page']
>['blocks'];

export const renderBundlePageBlock = (
  block: BlockType<BlocksArrayType>,
  data: LadderBundleProductPageBySlugQuery['page']
) => renderBlockWithId(block, getBlock(block, data));

function getBlock(
  block: BlockType<BlocksArrayType>,
  data: LadderBundleProductPageBySlugQuery['page']
) {
  const type = block.__typename;
  const id = block._key;

  const { bundle, name } = data || {};
  const { review, reviewId, couponCode, staticReviewData, images } =
    bundle || {};

  switch (type) {
    case 'SanityEmailSignupBlock':
      return (
        <SanityEmailSignUp
          key={id}
          fields={block as SanityEmailSignupBlockFragment}
        />
      );
    case 'SanitySimpleMediaWithTextBlock':
      return (
        <PDPPageContainer>
          <SimpleMediaWithText
            fields={block as SanitySimpleMediaWithTextBlockFragment}
          />
        </PDPPageContainer>
      );
    case 'SanitySellPageSpacer':
      return (
        <Spacer
          key={id}
          backgroundColor={
            (block as SanitySellPageSpacer)?.backgroundColor?.hex
          }
          margin={(block as SanitySellPageSpacer)?.margin}
          marginMobile={(block as SanitySellPageSpacer)?.marginMobile}
        />
      );
    case 'SanityBundleItemList':
      return <BundleItemList />;
    case 'SanityProductReviewsBlock':
      return (
        <SanityReviewSection
          reviewId={reviewId}
          review={review}
          staticReviewData={staticReviewData}
          product={{
            title: name,
            description: `Bundle ${name}`,
            image: images?.[0]?.image?.asset?.url,
          }}
          key={id}
          fields={block as ProductReviewsBlockFragment}
        />
      );
    case 'SanityLadderProductSlider':
      return (
        <ProductSliderSanity
          key={id}
          fields={block as SanityLadderProductSliderFragment}
        />
      );
    case 'SanityProductAbout':
      return (
        <ProductAboutSanity
          key={id}
          fields={block as SanityProductAboutFragment}
        />
      );
    case 'SanityProductFAQ':
      return (
        <ProductFAQSanity key={id} fields={block as SanityProductFaqFragment} />
      );

    case 'SanitySquareImageSlider':
      return (
        <SanitySquareImageSlider
          key={id}
          fields={block as SanitySquareImageSliderFragment}
        />
      );
    case 'SanityProductBundleAddToCart':
      return (
        <BundleAddToCartBlock
          title={name}
          images={images}
          couponCode={couponCode}
          description={(block as BundleAddToCartBlockFragment).description}
          addToCartAction={
            ((block as BundleAddToCartBlockFragment).buyButtonOption as
              | 'checkout'
              | 'cart') || DEFAULT_BUY_BUTTON_ACTION
          }
        />
      );
    // Removed blocks
    case 'SanityProp65Block':
      return null;
    default:
      return `Unrecognized block type: ${type}`;
  }
}
